import {LaravelEcho} from "~/Echo.js";
import {DsUser} from "~/helper/User/DsUser";
import {usePage} from "@inertiajs/vue3";
import {computed, Ref, ref, WritableComputedRef} from "vue";
import {DsRoute} from "~/helper/DsRoute";
import axios from "axios";
import { app_url } from "../App";
import {env} from "~/utils";

const page = usePage();
// @ts-ignore
export const notifications : WritableComputedRef<NotificationType[]> = computed({
    get() {
        return notification_list.value.sort((a, b) => {
            if (a.is_read === b.is_read) {
                return 0;
            }
            return a.is_read ? 1 : -1;
        });
    },
    set(value : NotificationType[]) {
        notification_list.value = value;
    }
});

export const is_listening_for_notifications = ref(false);

const notification_list: Ref<NotificationType[]> = ref([]);

export const haveUnreadNotifications = computed(() => {
    return notifications.value.some((notification) => !notification.is_read);
});

export const unreadNotifications = computed(() => {
    return notifications.value.filter((notification) => !notification.is_read);
});

export type NotificationType = {
    id: number,
    type: string,
    title: string,
    message: string,
    href: string,
    is_read: boolean,
    user: {
        id: number,
        firstname: string,
        lastname: string,
        email: string,
    }
    created_at: string,
    updated_at: string,
}

export class DsNotification {
    static listen() {
        if(!DsUser.isAuth()){
            return;
        }
        console.log('🔔 🔵 Initializing notifications ...');
        LaravelEcho.private(`notification.${DsUser.getUser('id')}`).listen('.new', (data: NotificationType) => {
            if(notification_list.value.some((notification) => notification.id === data.id)){
                console.warn(`💥 Notification #${data.id} est déjà dans la liste`);
                return;
            }

            notifications.value.unshift(data);
            if (Notification.permission !== "granted") {
                return;
            }

            let notification = new Notification(data.title, {
                icon: `${app_url}/images/icons/icon-96x96.png`,
                body: data.message,
            });

            notification.onclick = () => {
                this.handleNotification(notifications.value.find((n) => n.id === data.id));
            }
        }).subscribed(() => {
            is_listening_for_notifications.value = true;
            console.log(`🔔 🟢 Subscribed to notification channel with id ${DsUser.getUser('id')}`);
        }).error((error) => {
            is_listening_for_notifications.value = false;
            console.error(`🔔 🔴 Error subscribing to private channel notification.${DsUser.getUser('id')}`, error);
        });
    }
    static async handleNotification(notification: NotificationType, ignore_redirect = false) {

        if(notification.is_read && !notification.href){
            return;
        }

        const route = DsRoute.route('user.notification.read', {notification_id: notification.id, ignore_redirect: ignore_redirect})

        notification.is_read = true;

        if (notification.href && !ignore_redirect) {
            window.open(route, '_blank');
            return;
        }

        const response = await axios.get(route);
        if(response.status !== 200) {
            console.error('Erreur de communication avec le serveur');
            return;
        }
    }

    static async markAllAsRead(){
        notifications.value.forEach((notification) => {
            notification.is_read = true;
        });
        const response = await axios.get(DsRoute.route('user.notification.readAll'));
    }
    static loadAlreadyRegisterNotifications(){
        notifications.value = DsUser.getUserNotification();
    }

}
